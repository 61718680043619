import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Switch} from 'react-router-dom';
import PageChargement from './PageChargement';
import CheminSecurise from './CheminSecurise';
import ConnectionSystem from './ConnectionSystem';
import PageAdmin from './PageAdmin';
import ErreurPage from './ErreurPage';
import PageLogin from './PageLogin';
import './App.css';
import './responsive.css';

function RootPage() {
  return (
  	<Router>
  		<ConnectionSystem>
  			<Routes>
          <Route path="/securite" element={<PageLogin />} />
          <Route path="/" element={<CheminSecurise component={PageAdmin} />} />
  			</Routes>
  		</ConnectionSystem>
  	</Router>
  );
}

function App() {
  const [chargement, setChargement] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setChargement(false);
    }, 3600)
    return () => clearTimeout(timer)
  }, []);

  return (
    <div>
      {chargement ? <PageChargement /> : <RootPage />}
    </div>
  );
}

export default App;
