import React from 'react';
import {css} from '@emotion/react';
import {ClipLoader, BarLoader} from 'react-spinners';

const styleSpinner = css`
	display:block;
	margin: auto;
`;

function PageChargement() {
	return (
		<div className="row w-100" id="page-chargement">
			<BarLoader color={'green'} css={styleSpinner} height={6} />
		</div>
	);
}

export default PageChargement;
