import React, {useState, useEffect, useContext, useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

function AffichagePanier({idPanier}) {
    //
    const [dataPanier, setDatapanier] = useState([]);
	//
	useEffect(() => {
        getDatapanier();    
	}, []);
    //
    const getDatapanier = async() => {
        axios.post('https://api.hermannlepatriote.com/_010111SQL_/obtenirlesproduitsdupanier', {idPanier})
        .then(response => {
            switch (response.data.erreur) {
                case 'ErreurProduitPanier': 
                    return alert('Erreur du panier '+response.data.message);
                case 'SuccessProduitPanier':
                    return getcartliste(response.data.listepanier);
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur de listing du panier',error);
        });
    }
    function getcartliste(c) {
    	setDatapanier(c);
    }


	return (
	    <div className="flex w-full">
	    	<ul>
	    		{
	    			dataPanier && dataPanier.length > 0 ? (
	    				dataPanier.map((p) => (
		    				<li className="flex flex-wrap items-center bg-gray-100 mb-3 p-1 rounded">
		    					<p className="text-xs w-10/12">
		    						{p.achat_nom}<br/>
		    						<span className="text-red-500">{p.achat_qte}x ${p.achat_prix/p.achat_qte} = ${p.achat_prix}</span> 
		    					</p>
		    					<img className="w-2/12 rounded" src={p.achat_image} />
		    				</li>
		    			))
	    			) : (
	    				<p> Aucun produit dans le panier </p>
	    			)
	    		}	
	    	</ul>
		</div>
  	);
}

export default AffichagePanier;
