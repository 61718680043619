import React, {useState, useEffect, useContext, useRef} from 'react';
import axios from 'axios';
import {ConnectionContext} from './ConnectionSystem';
import {Link, useNavigate} from 'react-router-dom';

function PageLogin() {
	const navigate = useNavigate();
	const [userLogin, setUserlogin] = useState('');
	const [userMotdepass, setUsermotdepass] = useState('');
	const refLoginNom = useRef(null);
	const refLoginPsw = useRef(null);
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	useEffect(() => {
		refLoginNom.current.focus();
		if (hermannlepatrioteClientconnecte()) {
			navigate('/');
		}
	}, []);
	/*const getUserip = async() => {
		try {
			const resp = await axios.get('https://api.ipify.org/?format=json');
			setUserip(resp.data.ip);
		} catch(error) {
			console.error('Erreur sur adresse IP', error);
		}
	};*/
	async function handleSubmit() {
		if (!userLogin) {
			alert('Entrez votre login');
			refLoginNom.current.focus();
		} else {
			if (!userMotdepass) {
				alert('Entrez votre mot de passe');
				refLoginPsw.current.focus();
			} else {
				axios.post('https://api.hermannlepatriote.com/datab/login', {userLogin, userMotdepass})
				.then(response => {
					switch (response.data.erreur) {
						case 'EmailUnset':
							alert(response.data.message);
							return;
						case 'PswUnset':
							alert(response.data.message);
							return;
						case 'PswError':
							alert(response.data.message);
							refLoginPsw.current.focus();
							return;
						case 'EmailError':
							alert(response.data.message);
							refLoginNom.current.focus();
							return;
						case 'Success':
							return setLogin(response.data.token);
					}
				})
				.catch(error => {
					console.error('Connection erronée !', error);
				});
			}
		}
	}
	function setLogin(t) {
		alert('Connection acceptée !');
		hermannlepatrioteClientlogin(t);
	}
	function handleReset(e) {
		e.preventDefault();
		setUserlogin('');
		setUsermotdepass(''); 
		refLoginNom.current.focus();
	}	
	return (
		<div id="page-securite" className="bg-pink-100">
			<div id="forum-page-login">
				<p className='text-xl mb-6' >Veuillez vous connecter </p>
				<div className="mb-4">
					<label className="text-xs">Login:</label><br/>
					<input ref={refLoginNom} className="rounded outline-none border-0 text-base px-3 py-2" type="text" value={userLogin} onChange={(e) => setUserlogin(e.target.value)} />
				</div>
				<div>
					<label className="text-xs">Mot de passe:</label><br/>
					<input ref={refLoginPsw} className="rounded outline-none border-0 text-base px-3 py-2" type="password" value={userMotdepass} onChange={(e) => setUsermotdepass(e.target.value)} />
				</div>
				<br/>
				<button onClick={() => handleSubmit()} className="bg-green-400 text-white px-3 py-1 rounded mr-1">Se Connecter</button>&nbsp;
				<button className="bg-gray-500 text-white px-3 py-1 rounded mr-1" onClick={handleReset}>Effacer</button>
			</div>			
		</div>
	);
}

export default PageLogin;
